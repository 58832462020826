var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "onlineOrder" }, [
    _c("div", { staticClass: "search" }, [
      _c("div", { staticClass: "left-search" }, [
        _c(
          "div",
          { staticClass: "row" },
          [
            _c(
              "div",
              { staticClass: "anyTime x-f" },
              [
                _c("div", { staticClass: "label" }, [_vm._v("日期")]),
                _c("el-date-picker", {
                  attrs: {
                    type: "datetimerange",
                    "range-separator": "-",
                    "start-placeholder": "开始日期",
                    "end-placeholder": "结束日期",
                    "default-time": ["00:00:00", "23:59:59"],
                    "value-format": "yyyy-MM-dd HH:mm:ss",
                  },
                  model: {
                    value: _vm.datetime,
                    callback: function ($$v) {
                      _vm.datetime = $$v
                    },
                    expression: "datetime",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-radio-group",
              {
                staticClass: "quickTime",
                model: {
                  value: _vm.queryParams.filterTime,
                  callback: function ($$v) {
                    _vm.$set(_vm.queryParams, "filterTime", $$v)
                  },
                  expression: "queryParams.filterTime",
                },
              },
              [
                _c("el-radio-button", { attrs: { label: "today" } }, [
                  _vm._v("今天"),
                ]),
                _c("el-radio-button", { attrs: { label: "yesterday" } }, [
                  _vm._v("昨天"),
                ]),
                _c("el-radio-button", { attrs: { label: "thisWeek" } }, [
                  _vm._v("本周"),
                ]),
              ],
              1
            ),
            _c("el-input", {
              attrs: {
                placeholder: "请输入单据编号/商品编码/商品名称/姓名/电话",
              },
              model: {
                value: _vm.queryParams.query,
                callback: function ($$v) {
                  _vm.$set(_vm.queryParams, "query", $$v)
                },
                expression: "queryParams.query",
              },
            }),
            _c(
              "el-button",
              {
                attrs: { type: "primary", icon: "el-icon-search" },
                on: {
                  click: function ($event) {
                    return _vm.searchBtn()
                  },
                },
              },
              [_vm._v("搜索 ")]
            ),
          ],
          1
        ),
        _c("div", { staticClass: "row" }, [
          _c(
            "div",
            { staticClass: "state x-f" },
            [
              _c("div", { staticClass: "label" }, [_vm._v("状态")]),
              _c(
                "el-radio-group",
                {
                  on: {
                    change: function ($event) {
                      return _vm.getList(true)
                    },
                  },
                  model: {
                    value: _vm.queryParams.orderInnerStatus,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderInnerStatus", $$v)
                    },
                    expression: "queryParams.orderInnerStatus",
                  },
                },
                [
                  _c("el-radio-button", { attrs: { label: 1 } }, [
                    _vm._v("未制作"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 2 } }, [
                    _vm._v("制作中"),
                  ]),
                  _c("el-radio-button", { attrs: { label: 3 } }, [
                    _vm._v("制作完成"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "type" },
            [
              _vm._v(" 类型 "),
              _c(
                "el-select",
                {
                  attrs: { placeholder: "请选择订单类型", clearable: "" },
                  model: {
                    value: _vm.queryParams.orderType,
                    callback: function ($$v) {
                      _vm.$set(_vm.queryParams, "orderType", $$v)
                    },
                    expression: "queryParams.orderType",
                  },
                },
                _vm._l(_vm.dict.type.order_type, function (dict) {
                  return _c("el-option", {
                    key: dict.value,
                    attrs: { label: dict.label, value: dict.value },
                  })
                }),
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { staticClass: "right-control" }, [
        _c(
          "div",
          [
            _vm._v(" 自动接单 "),
            _c("el-switch", {
              on: { change: _vm.orderState },
              model: {
                value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_71,
                callback: function ($$v) {
                  _vm.$set(_vm.shopOrderTip, "shop_ORDER_CONFIG_TIP_71", $$v)
                },
                expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_71",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm._v(" 有新订单时语音提示 "),
            _c("el-switch", {
              on: { change: _vm.orderState },
              model: {
                value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_72,
                callback: function ($$v) {
                  _vm.$set(_vm.shopOrderTip, "shop_ORDER_CONFIG_TIP_72", $$v)
                },
                expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_72",
              },
            }),
          ],
          1
        ),
        _c(
          "div",
          [
            _vm._v(" 有新订单时消息弹窗提示 "),
            _c("el-switch", {
              on: { change: _vm.orderState },
              model: {
                value: _vm.shopOrderTip.shop_ORDER_CONFIG_TIP_73,
                callback: function ($$v) {
                  _vm.$set(_vm.shopOrderTip, "shop_ORDER_CONFIG_TIP_73", $$v)
                },
                expression: "shopOrderTip.shop_ORDER_CONFIG_TIP_73",
              },
            }),
          ],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "content" }, [
      _c("div", { staticClass: "orderDetail" }, [
        _c("div", { staticClass: "orderReceipt" }, [
          _c("div", { staticClass: "title" }, [_vm._v("线上订单")]),
          _c(
            "div",
            { staticClass: "orderContent" },
            [
              _c("el-scrollbar", [
                _c("div", { staticClass: "orderInfo borderB" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 订单编号:" + _vm._s(this.orderDetails.billNo) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 下单时间:" + _vm._s(this.orderDetails.billDate) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 取货门店:" + _vm._s(this.orderDetails.shopName) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 送货时间:" +
                          _vm._s(this.orderDetails.deliveryDate) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 取货方式:" +
                          _vm._s(this.orderDetails.fetchTypeName) +
                          " "
                      ),
                    ]),
                    _c("div", { staticClass: "val" }),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v("备注:" + _vm._s(this.orderDetails.remark)),
                    ]),
                    _c("div", { staticClass: "val" }),
                  ]),
                ]),
                _c("div", { staticClass: "goodsInfo" }, [
                  _c("div", { staticClass: "goodsTitle-list" }, [
                    _c("div", { staticClass: "goodsTitle" }, [_vm._v("编号")]),
                    _c("div", { staticClass: "goodsTitle" }, [_vm._v("名称")]),
                    _c("div", { staticClass: "goodsTitle" }, [_vm._v("数量")]),
                    _c("div", { staticClass: "goodsTitle" }, [_vm._v("单价")]),
                    _c("div", { staticClass: "goodsTitle" }, [_vm._v("小计")]),
                  ]),
                  _c(
                    "div",
                    { staticClass: "goodsList" },
                    [
                      _vm._l(_vm.orderGoodsDetails, function (item, index) {
                        return _c(
                          "div",
                          { key: index, staticClass: "goodsItem" },
                          [
                            _c("div", [_vm._v(_vm._s(item.goodsNo))]),
                            _c("div", { staticClass: "goodsItemName" }, [
                              _vm._v(_vm._s(item.goodsName)),
                            ]),
                            _c("div", [_vm._v(_vm._s(item.unitQty))]),
                            _c("div", [_vm._v(_vm._s(item.unitPrice))]),
                            _c("div", [_vm._v(_vm._s(item.subtotal))]),
                          ]
                        )
                      }),
                      _c("div", { staticClass: "goodsTotal" }, [
                        _vm._v(
                          " 共" + _vm._s(this.orderDetails.orderQty) + "件商品 "
                        ),
                      ]),
                    ],
                    2
                  ),
                ]),
                _c("div", { staticClass: "payInfo borderB" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label width105" }, [
                      _vm._v(
                        " 原价金额:" +
                          _vm._s(this.orderDetails.orderOldMoney) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label width105" }, [
                      _vm._v(
                        " 优惠金额:" +
                          _vm._s(this.orderDetails.orderDiscountMoney) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label width105" }, [
                      _vm._v(
                        " 配送费用:" +
                          _vm._s(this.orderDetails.deliveryFee) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label width105" }, [
                      _vm._v(
                        " 优惠券折扣:" +
                          _vm._s(this.orderDetails.couponDiscount) +
                          " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 本单合计金额:" +
                          _vm._s(this.orderDetails.orderMoney) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
                _c("div", { staticClass: "clientInfo" }, [
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 姓名:" + _vm._s(this.orderDetails.receiverName) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 电话:" + _vm._s(this.orderDetails.receiverPhone) + " "
                      ),
                    ]),
                  ]),
                  _c("div", { staticClass: "row" }, [
                    _c("div", { staticClass: "label" }, [
                      _vm._v(
                        " 地址:" +
                          _vm._s(this.orderDetails.receiverAddress) +
                          " "
                      ),
                    ]),
                  ]),
                ]),
              ]),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "footer-btn" },
            [
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !this.orderDetails.billId,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleButtonClick(1)
                    },
                  },
                },
                [_vm._v("打印 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !this.orderDetails.billId,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleButtonClick(2)
                    },
                  },
                },
                [_vm._v("开始制作 ")]
              ),
              _c(
                "el-button",
                {
                  attrs: {
                    disabled: !this.orderDetails.billId,
                    type: "primary",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.handleButtonClick(3)
                    },
                  },
                },
                [_vm._v("制作完成 ")]
              ),
            ],
            1
          ),
        ]),
      ]),
      _c(
        "div",
        { staticClass: "orderList" },
        [
          _c(
            "el-table",
            {
              staticStyle: { width: "100%", height: "calc(100% - 46px)" },
              attrs: {
                data: _vm.tableData,
                border: "",
                height: "520",
                "max-height": "600px",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  type: "index",
                  label: "序号",
                  width: "50",
                  align: "center",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  "show-overflow-tooltip": "",
                  label: "订单编号",
                  align: "center",
                  prop: "billNo",
                  width: "180",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-link",
                          {
                            attrs: { type: "primary", target: "_blank" },
                            on: {
                              click: function ($event) {
                                return _vm.getOrderDetails(scope.row)
                              },
                            },
                          },
                          [_vm._v(" " + _vm._s(scope.row.billNo) + " ")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _vm._l(_vm.tableFields, function (item) {
                return _c("el-table-column", {
                  directives: [
                    {
                      name: "loading",
                      rawName: "v-loading",
                      value: _vm.loadingTable,
                      expression: "loadingTable",
                    },
                  ],
                  key: item.title,
                  attrs: {
                    "show-overflow-tooltip": "",
                    prop: item.prop,
                    label: item.title,
                    width: item.width,
                    align: "center",
                  },
                })
              }),
            ],
            2
          ),
          _c("pagination", {
            directives: [
              {
                name: "show",
                rawName: "v-show",
                value: _vm.total > 0,
                expression: "total > 0",
              },
            ],
            attrs: {
              total: _vm.total,
              page: _vm.queryParams.pageNum,
              limit: _vm.queryParams.pageSize,
            },
            on: {
              "update:page": function ($event) {
                return _vm.$set(_vm.queryParams, "pageNum", $event)
              },
              "update:limit": function ($event) {
                return _vm.$set(_vm.queryParams, "pageSize", $event)
              },
              pagination: function ($event) {
                return _vm.getList()
              },
            },
          }),
        ],
        1
      ),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }