<template>
  <div class="wrap">
    <TablePage ref="tablePage" v-show="showList" v-model="options" @handleEvent="handleEvent" />
    <TablePage ref="tablePage" v-if="!showList" class="detail" v-model="optionsDetail" @handleEvent="handleEvent" />
  </div>
</template>
<script>
import TablePage from '@/components/tablePage'
import {
  storeQueryAPI,
  getStoreBookDetail,
  listShopInventoryQuerySummary
} from '@/api/decoration/report/storeQuery' // 裱花库存查询
export default {
  name: 'StockQuery',
  components: { TablePage },
  data() {
    return {
      showList: true,
      openStockQuery: false,
      goodsDetailData: {},
      options: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        getSummaryApi: listShopInventoryQuerySummary,
        exportOption: {
          ossKeyUrl:'/api/system/shop/report/inventory/query/getListShopInventoryQueryExport',
          fastExportUrl:'/api/system/decoration/store/report/listBillStoreQueryExport',
          exportName: '裱花库存查询'
        },
        title: '裱花库存查询',
        tableTitle: '裱花库存查询',
        search: [
          {
            label: '单据日期',
            type: 'datetimerange',
            model: '',
            filterFnc: ([a, b] = []) => ({ beginBillDate: a, endBillDate: b })
          },
          {
            keyIndex: 0,
            format: 'datetimerange',
            type: 'timeRange',
            defaultValue: 'day',
            data: ['day', 'yesterday', 'week', 'lastWeek', 'month', 'lastMonth']
          },
          {
            type: 'filters',
            tip: '全部/裱花间编号/裱花间名称/商品编号/商品名称/分类编号/分类名称',
            isRestore: true,
            model: '',
            filters: [
              { filter: 'query', label: '全部' },
              { filter: 'roomNos', label: '裱花间编号' },
              { filter: 'roomNames', label: '裱花间名称' },
              { filter: 'goodsNos', label: '商品编号' },
              { filter: 'goodsNames', label: '商品名称' },
              { filter: 'categoryNos', label: '分类编号' },
              { filter: 'categoryNames', label: '分类名称' }
            ]
          },
          this.$select({
            key: 'listGoods',
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'goods' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'listCategory',
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                buttons: [
                  {
                    type: 'more',
                    option: {
                      title: '选择商品类别',
                      width: 1250,
                      type: 'TreeAndTable',
                      formData: this.$dialog({ key: 'category' })
                    }
                  }
                ]
              }
            }
          }),
          this.$select({
            key: 'decorationListRoom',
            option: {
              seniorSearch: true,
              option: {
                multiple: true,
                // buttons: [
                //   {
                //     type: 'more',
                //     option: {
                //       title: '选择门店',
                //       width: 1250,
                //       type: 'TreeAndTable',
                //       formData: this.$dialog({ key: 'shop' })
                //     }
                //   }
                // ]
              }
            }
          }),
          { type: 'button', tip: '查询', btnType: 'primary', click: 'search' },
          {
            type: 'button',
            tip: '高级查询',
            btnType: 'primary',
            click: 'seniorSearch'
          },
          { type: 'button', tip: '重置', click: 'reset' }
        ],
        getListApi: storeQueryAPI,
        columns: [
          // { label: '仓库', prop: 'storeName', minWidth: 120, fixed: true },
          // {
          //   label: '商品编码',
          //   prop: 'goodsNo',
          //   minWidth: 120,
          //   fixed: true,
          //   link: true,
          //   click: 'oepnDetail'
          // },
          { label: '日期', prop: 'dayDateStr', minWidth: 120 },
          { label: '裱花间编号', prop: 'roomNo', minWidth: 120 },
          { label: '裱花间名称', prop: 'roomName', minWidth: 160 },
          { label: '仓库编号', prop: 'storeNo', minWidth: 120 },
          { label: '仓库名称', prop: 'storeName', minWidth: 160 },
          { label: '商品分类', prop: 'categoryName', minWidth: 120 },
          {
            label: '商品编码',
            prop: 'goodsNo',
            minWidth: 150,
            // fixed: true,
            link: true,
            click: 'oepnDetail'
          },
          { label: '商品名称', prop: 'goodsName', minWidth: 160 },
          { label: '条码', prop: 'barcode', minWidth: 160 },
          { label: '规格', prop: 'goodsSpec', minWidth: 120 },
          { label: '单位', prop: 'unitName', minWidth: 100 },
          { label: '期初数', prop: 'firstQty', minWidth: 100 },
          { label: '收入数', prop: 'inQty', minWidth: 100 },
          { label: '发出数', prop: 'outQty', minWidth: 100 },
          { label: '期末数', prop: 'finalQty', minWidth: 100 },
          { label: '期末金额', prop: 'finalMoney', minWidth: 100 },
          { label: '仓库调入数量', prop: 'moveInQty', minWidth: 100 },
          { label: '仓库调入金额', prop: 'moveInMoney', minWidth: 100 },
          { label: '仓库调出数量', prop: 'moveOutQty', minWidth: 100 },
          { label: '仓库调出金额', prop: 'moveOutMoney', minWidth: 100 },
          { label: '门店采购数', prop: 'shopPurQty', minWidth: 100 },
          { label: '门店采购金额', prop: 'shopPurMoney', minWidth: 100 },
          { label: '门店生产数', prop: 'shopProduceInQty', minWidth: 100 },
          { label: '门店生产金额', prop: 'shopProduceInMoney', minWidth: 100 },
          { label: '门店报废数', prop: 'shopWasteQty', minWidth: 100 },
          { label: '门店报废金额', prop: 'shopWasteMoney', minWidth: 100 },
          { label: '门店调出数', prop: 'shopMoveOutQty', minWidth: 100 },
          { label: '门店调出金额', prop: 'shopMoveOutMoney', minWidth: 100 },
          { label: '门店原料消耗数', prop: 'shopLeadMaterialQty', minWidth: 120 },
          {
            label: '门店原料消耗金额',
            prop: 'shopLeadMaterialMoney',
            minWidth: 130
          },
          { label: '门店零售数量', prop: 'shopRetailQty', minWidth: 100 },
          { label: '门店零售金额', prop: 'shopRetailMoney', minWidth: 100 },
          { label: '门店盘亏数', prop: 'shopLoseQty', minWidth: 100 },
          { label: '门店盘亏金额', prop: 'shopLoseMoney', minWidth: 100 },
          { label: '门店盘盈数', prop: 'shopFullQty', minWidth: 100 },
          { label: '门店盘盈金额', prop: 'shopFullMoney', minWidth: 100 }
        ],
        summary: [
          'firstQty',
          'inQty',
          'outQty',
          'finalQty',
          'finalMoney',
          'moveInQty',
          'moveInMoney',
          'moveOutQty',
          'moveOutMoney',
          'shopPurQty',
          'shopPurMoney',
          'shopProduceInQty',
          'shopProduceInMoney',
          'shopWasteQty',
          'shopWasteMoney',
          'shopMoveOutQty',
          'shopMoveOutMoney',
          'shopLeadMaterialQty',
          'shopLeadMaterialMoney',
          'shopRetailQty',
          'shopRetailMoney',
          'shopLoseQty',
          'shopLoseMoney',
          'shopFullQty',
          'shopFullMoney'
        ]
      },
      optionsDetail: {
        listNo: true,
        loading: true,
        pagination: {
          total: 0,
          page: 1,
          size: 15
        },
        body: {},
        title: '商品库存明细',
        tableTitle: '商品库存明细',
        rowKey: 'billId',
        // list: [],
        getListApi: getStoreBookDetail,
        getDataKey: e => {
          this.optionsDetail.search = this.optionsDetail.search.map(item => ({
            ...item,
            model: e.data.mainReport[item.filter]
          }))
          return {
            list: e.data.tableDataInfo.rows,
            total: e.data.tableDataInfo.total
          }
        },
        defaultBody: {
          goodsId: '',
          storeId: '',
          batchNo: ''
        },
        search: [
          {
            type: 'input',
            label: '仓库',
            tip: ' ',
            model: '',
            filter: 'storeName',
            disabled: true
          },
          {
            type: 'input',
            label: '商品编码',
            tip: ' ',
            model: '',
            filter: 'goodsNo',
            disabled: true
          },
          {
            type: 'input',
            label: '商品名称',
            tip: ' ',
            model: '',
            filter: 'goodsName',
            disabled: true
          },
          {
            type: 'input',
            label: '商品条码',
            tip: ' ',
            model: '',
            filter: 'barcode',
            disabled: true
          },
          {
            type: 'input',
            label: '商品批号',
            tip: ' ',
            model: '',
            filter: 'batchNo',
            disabled: true
          },
          {
            type: 'input',
            label: '规格',
            tip: ' ',
            model: '',
            filter: 'goodsSpec',
            disabled: true
          },
          {
            type: 'input',
            label: '基础单位',
            tip: ' ',
            model: '',
            filter: 'unitName',
            disabled: true
          },
          {
            type: 'input',
            label: '成本价',
            tip: ' ',
            model: '',
            filter: 'costPrice',
            disabled: true
          },
          {
            type: 'input',
            label: '期初数',
            tip: ' ',
            model: '',
            filter: 'firstQty',
            disabled: true
          },
          {
            type: 'input',
            label: '期初金额',
            tip: ' ',
            model: '',
            filter: 'firstMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '入库数',
            tip: ' ',
            model: '',
            filter: 'inQty',
            disabled: true
          },
          {
            type: 'input',
            label: '入库金额',
            tip: ' ',
            model: '',
            filter: 'inMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '出库数',
            tip: ' ',
            model: '',
            filter: 'outQty',
            disabled: true
          },
          {
            type: 'input',
            label: '出库金额',
            tip: ' ',
            model: '',
            filter: 'outMoney',
            disabled: true
          },
          {
            type: 'input',
            label: '期末数',
            tip: ' ',
            model: '',
            filter: 'finalQty',
            disabled: true
          },
          {
            type: 'input',
            label: '期末金额',
            tip: ' ',
            model: '',
            filter: 'finalMoney',
            disabled: true
          },
          { type: 'button', tip: '关闭', btnType: 'primary', click: 'close' }
        ],
        summary: [
          'firstQty',
          'firstMoney',
          'inQty',
          'inMoney',
          'outQty',
          'outMoney',
          'finalQty',
          'finalMoney'
        ],
        columns: [
          { label: '记账时间', prop: 'auditTime', fixed: true, minWidth: 120 },
          {
            label: '单据编号',
            prop: 'billNo',
            minWidth: 120,
            fixed: true,
            link: true,
            click: 'getBillData'
          },
          { label: '单据日期', prop: 'billDate', minWidth: 100 },
          { label: '单据类型', prop: 'billTypeName', minWidth: 120 },
          { label: '商品批号', prop: 'batchNo', minWidth: 100 },
          { label: '期初数', prop: 'firstQty', minWidth: 80 },
          { label: '期初金额', prop: 'firstMoney', minWidth: 80 },
          { label: '入库数', prop: 'inQty', minWidth: 80 },
          { label: '入库金额', prop: 'inMoney', minWidth: 100 },
          { label: '出库数', prop: 'outQty', minWidth: 80 },
          { label: '出库金额', prop: 'outMoney', minWidth: 100 },
          { label: '期末数', prop: 'finalQty', minWidth: 80 },
          { label: '期末金额', prop: 'finalMoney', minWidth: 100 },
          { label: '备注', prop: 'remark' }
        ]
      }
    }
  },
  methods: {
    async handleEvent(type, row) {
      switch (type) {
        case 'close': // 关闭二级弹窗
          this.showList = true
          this.optionsDetail = {
            listNo: true,
            loading: true,
            pagination: {
              total: 0,
              page: 1,
              size: 15
            },
            body: {},
            title: '商品库存明细',
            tableTitle: '商品库存明细',
            rowKey: 'billId',
            // list: [],
            getListApi: getStoreBookDetail,
            getDataKey: e => {
              this.optionsDetail.search = this.optionsDetail.search.map(
                item => ({
                  ...item,
                  model: e.data.mainReport[item.filter]
                })
              )
              return {
                list: e.data.tableDataInfo.rows,
                total: e.data.tableDataInfo.total
              }
            },
            defaultBody: {
              goodsId: '',
              storeId: '',
              batchNo: ''
            },
            search: [
              {
                type: 'input',
                label: '仓库',
                tip: ' ',
                model: '',
                filter: 'storeName',
                disabled: true
              },
              {
                type: 'input',
                label: '商品编码',
                tip: ' ',
                model: '',
                filter: 'goodsNo',
                disabled: true
              },
              {
                type: 'input',
                label: '商品名称',
                tip: ' ',
                model: '',
                filter: 'goodsName',
                disabled: true
              },
              {
                type: 'input',
                label: '商品条码',
                tip: ' ',
                model: '',
                filter: 'barcode',
                disabled: true
              },
              {
                type: 'input',
                label: '商品批号',
                tip: ' ',
                model: '',
                filter: 'batchNo',
                disabled: true
              },
              {
                type: 'input',
                label: '规格',
                tip: ' ',
                model: '',
                filter: 'goodsSpec',
                disabled: true
              },
              {
                type: 'input',
                label: '基础单位',
                tip: ' ',
                model: '',
                filter: 'unitName',
                disabled: true
              },
              {
                type: 'input',
                label: '成本价',
                tip: ' ',
                model: '',
                filter: 'costPrice',
                disabled: true
              },
              {
                type: 'input',
                label: '期初数',
                tip: ' ',
                model: '',
                filter: 'firstQty',
                disabled: true
              },
              {
                type: 'input',
                label: '期初金额',
                tip: ' ',
                model: '',
                filter: 'firstMoney',
                disabled: true
              },
              {
                type: 'input',
                label: '入库数',
                tip: ' ',
                model: '',
                filter: 'inQty',
                disabled: true
              },
              {
                type: 'input',
                label: '入库金额',
                tip: ' ',
                model: '',
                filter: 'inMoney',
                disabled: true
              },
              {
                type: 'input',
                label: '出库数',
                tip: ' ',
                model: '',
                filter: 'outQty',
                disabled: true
              },
              {
                type: 'input',
                label: '出库金额',
                tip: ' ',
                model: '',
                filter: 'outMoney',
                disabled: true
              },
              {
                type: 'input',
                label: '期末数',
                tip: ' ',
                model: '',
                filter: 'finalQty',
                disabled: true
              },
              {
                type: 'input',
                label: '期末金额',
                tip: ' ',
                model: '',
                filter: 'finalMoney',
                disabled: true
              },
              {
                type: 'button',
                tip: '关闭',
                btnType: 'primary',
                click: 'close'
              }
            ],
            summary: [
              'firstQty',
              'firstMoney',
              'inQty',
              'inMoney',
              'outQty',
              'outMoney',
              'finalQty',
              'finalMoney'
            ],
            columns: [
              {
                label: '记账时间',
                prop: 'auditTime',
                fixed: true,
                minWidth: 120
              },
              {
                label: '单据编号',
                prop: 'billNo',
                minWidth: 120,
                fixed: true,
                link: true,
                click: 'getBillData'
              },
              { label: '单据日期', prop: 'billDate', minWidth: 100 },
              { label: '单据类型', prop: 'billTypeName', minWidth: 120 },
              { label: '商品批号', prop: 'batchNo', minWidth: 100 },
              { label: '期初数', prop: 'firstQty', minWidth: 80 },
              { label: '期初金额', prop: 'firstMoney', minWidth: 80 },
              { label: '入库数', prop: 'inQty', minWidth: 80 },
              { label: '入库金额', prop: 'inMoney', minWidth: 100 },
              { label: '出库数', prop: 'outQty', minWidth: 80 },
              { label: '出库金额', prop: 'outMoney', minWidth: 100 },
              { label: '期末数', prop: 'finalQty', minWidth: 80 },
              { label: '期末金额', prop: 'finalMoney', minWidth: 100 },
              { label: '备注', prop: 'remark' }
            ]
          }
          // this.optionsDetail.list = []
          break
        case 'oepnDetail': //获取点击商品信息
          this.optionsDetail.defaultBody.goodsId = row.goodsId
          this.optionsDetail.defaultBody.storeId = row.storeId
          this.optionsDetail.defaultBody.batchNo = row.batchNo
            ? row.batchNo
            : ''
          this.showList = false
          break
        case 'getBillData': // 明细点击
          // let billId = row.billId
          // let billType = row.billType
          // let routerName
          // if(billType === '102') routerName = 'PurchaseInstoreDetail'
          // if(billType === '103') routerName = 'PurchaseBackDetail'
          // if(billType === '105') routerName = 'SaleOutDetail'
          // if(billType === '106') routerName = 'SaleBackDetail'
          // if(billType === '107') routerName = 'storeCheckDetail'
          // if(billType === '108') routerName = 'storeAdjustDetail'
          // if(billType === '109') routerName = 'storeWasteDetail'
          // if(billType === '110') routerName = 'storeMoveDetail'
          // if(billType === '111') routerName = 'storeMoveOutDetail'
          // if(billType === '112') routerName = 'storeMoveInDetail'
          // if(billType === '113') routerName = 'storeCheckFullDetail'
          // if(billType === '114') routerName = 'storeCheckLoseDetail'
          // this.$router.push({
          //   name:routerName,
          //   query: {
          //     billId,
          //     type: 'Update'
          //   }
          // })
          break
        default:
          break
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.detail {
  ::v-deep .el-table {
    height: 500px !important;
    max-height: 500px !important;
    overflow: visible !important;
  }
}
</style>
