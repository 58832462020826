<template>
    <!-- 线上订单 -->
    <div class="onlineOrder">
        <div class="search">
            <!-- 搜索区域 -->
            <div class="left-search">
                <div class="row">
                    <div class="anyTime x-f">
                        <div class="label">日期</div>
                        <el-date-picker v-model="datetime" type="datetimerange" range-separator="-" start-placeholder="开始日期"
                            end-placeholder="结束日期" :default-time="['00:00:00', '23:59:59']"
                            value-format="yyyy-MM-dd HH:mm:ss">
                        </el-date-picker>
                    </div>
                    <el-radio-group v-model="queryParams.filterTime" class="quickTime">
                        <el-radio-button label="today">今天</el-radio-button>
                        <el-radio-button label="yesterday">昨天</el-radio-button>
                        <el-radio-button label="thisWeek">本周</el-radio-button>
                    </el-radio-group>
                    <el-input v-model="queryParams.query" placeholder="请输入单据编号/商品编码/商品名称/姓名/电话"></el-input>
                    <el-button type="primary" icon="el-icon-search" @click="searchBtn()">搜索
                    </el-button>
                </div>
                <div class="row">
                    <div class="state x-f">
                        <div class="label">状态</div>
                        <!-- ,1:未制作,2:制作中,3:制作完成 -->
                        <el-radio-group v-model="queryParams.orderInnerStatus" @change="getList(true)">
                            <el-radio-button :label="1">未制作</el-radio-button>
                            <el-radio-button :label="2">制作中</el-radio-button>
                            <el-radio-button :label="3">制作完成</el-radio-button>
                        </el-radio-group>
                    </div>
                    <div class="type">
                        类型

                        <el-select v-model="queryParams.orderType" placeholder="请选择订单类型" clearable>
                            <el-option v-for="dict in dict.type.order_type" :key="dict.value" :label="dict.label"
                                :value="dict.value" />
                        </el-select>

                        <!--  </el-select> -->
                    </div>
                </div>
            </div>
            <!-- 控制区域 -->
            <div class="right-control">
                <div>
                    自动接单
                    <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_71" @change="orderState"></el-switch>
                </div>
                <div>
                    有新订单时语音提示
                    <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_72" @change="orderState"></el-switch>
                </div>
                <div>
                    有新订单时消息弹窗提示
                    <el-switch v-model="shopOrderTip.shop_ORDER_CONFIG_TIP_73" @change="orderState"></el-switch>
                </div>
            </div>
        </div>
        <div class="content">
            <!-- 订单详情 -->
            <div class="orderDetail">
                <div class="orderReceipt">
                    <div class="title">线上订单</div>
                    <div class="orderContent">
                        <!-- 订单信息 -->

                        <el-scrollbar>
                            <div class="orderInfo borderB">
                                <div class="row">
                                    <div class="label">
                                        订单编号:{{ this.orderDetails.billNo }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        下单时间:{{ this.orderDetails.billDate }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        取货门店:{{ this.orderDetails.shopName }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        送货时间:{{ this.orderDetails.deliveryDate }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        取货方式:{{ this.orderDetails.fetchTypeName }}
                                    </div>
                                    <div class="val"></div>
                                </div>
                                <div class="row">
                                    <div class="label">备注:{{ this.orderDetails.remark }}</div>
                                    <div class="val"></div>
                                </div>
                            </div>
                            <!-- 商品信息 -->
                            <div class="goodsInfo">
                                <div class="goodsTitle-list">
                                    <div class="goodsTitle">编号</div>
                                    <div class="goodsTitle">名称</div>
                                    <div class="goodsTitle">数量</div>
                                    <div class="goodsTitle">单价</div>
                                    <div class="goodsTitle">小计</div>
                                </div>

                                <div class="goodsList">
                                    <div class="goodsItem" v-for="(item, index) in orderGoodsDetails" :key="index">
                                        <div>{{ item.goodsNo }}</div>
                                        <div class="goodsItemName">{{ item.goodsName }}</div>
                                        <div>{{ item.unitQty }}</div>
                                        <div>{{ item.unitPrice }}</div>
                                        <div>{{ item.subtotal }}</div>
                                    </div>

                                    <div class="goodsTotal">
                                        共{{ this.orderDetails.orderQty }}件商品
                                    </div>
                                </div>
                            </div>
                            <!-- 付款信息 -->
                            <div class="payInfo borderB">
                                <div class="row">
                                    <div class="label width105">
                                        原价金额:{{ this.orderDetails.orderOldMoney }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label width105">
                                        优惠金额:{{ this.orderDetails.orderDiscountMoney }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label width105">
                                        配送费用:{{ this.orderDetails.deliveryFee }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label width105">
                                        优惠券折扣:{{ this.orderDetails.couponDiscount }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        本单合计金额:{{ this.orderDetails.orderMoney }}
                                    </div>
                                </div>
                            </div>
                            <!-- 客户信息 -->
                            <div class="clientInfo">
                                <div class="row">
                                    <div class="label">
                                        姓名:{{ this.orderDetails.receiverName }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        电话:{{ this.orderDetails.receiverPhone }}
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="label">
                                        地址:{{ this.orderDetails.receiverAddress }}
                                    </div>
                                </div>
                            </div>
                        </el-scrollbar>
                    </div>
                    <div class="footer-btn">
                        <el-button :disabled="!this.orderDetails.billId" type="primary" @click="handleButtonClick(1)">打印
                        </el-button>
                        <el-button :disabled="!this.orderDetails.billId" type="primary" @click="handleButtonClick(2)">开始制作
                        </el-button>
                        <el-button :disabled="!this.orderDetails.billId" type="primary" @click="handleButtonClick(3)">制作完成
                        </el-button>
                    </div>
                </div>
            </div>
            <!-- 订单列表 -->
            <div class="orderList">
                <!-- 订单表格 -->
                <el-table :data="tableData" border height="520" max-height="600px"
                    style="width: 100%; height: calc(100% - 46px)">
                    <el-table-column type="index" label="序号" width="50" align="center">
                    </el-table-column>
                    <el-table-column show-overflow-tooltip label="订单编号" align="center" prop="billNo" width="180">
                        <template slot-scope="scope">
                            <el-link type="primary" @click="getOrderDetails(scope.row)" target="_blank">
                                {{ scope.row.billNo }}
                            </el-link>
                        </template>
                    </el-table-column>
                    <el-table-column show-overflow-tooltip v-loading="loadingTable" :prop="item.prop" :label="item.title"
                        :width="item.width" v-for="item in tableFields" :key="item.title" align="center" />
                </el-table>
                <!-- 表格分页 -->
                <pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
                    :limit.sync="queryParams.pageSize" @pagination="getList()" />
            </div>
        </div>
    </div>
</template>

<script>
import {
    shopOrderTipConfig,
    saveShopOrderTipConfig //门店订单中台状态
} from '@/api/shop/orderCenter/orderManage' //门店中台
import {
    getRoomOrder,
    printOrder,
    orderDoing,
    orderDone,
    getOrderMiddleFroundOneInfo
} from "@/api/produce/decoration/index.js";

export default {
    name: 'orderManage',
    dicts: ['order_type'],
    data() {
        return {
            orderListDetail: {},
            //详情
            orderDetails: {},
            orderGoodsDetails: [], //详情中的订单
            datetime: undefined, //单据查询时间
            value: '',
            value1: '',
            orderDateFilterTime: '', // 下单时间过滤参数(今天[today]、昨天[yesterday]、本周[thisWeek]
            // options: [{}],
            tableData: [], //订单中台表格列表
            //表格列字段
            tableFields: [
                { prop: 'decorationRoomName', title: '裱花间名称', width: '160' },
                { prop: 'decorationRoomNo', title: '裱花间编号', width: '160' },
                { prop: 'billDate', title: '订单时间', width: '160' },
                { prop: 'orderStatus', title: '订单状态', width: '100' },
                { prop: 'orderTypeName', title: '订单类型', width: '100' },
                { prop: 'orderPrintCount', title: '打印次数', width: '100' },
                { prop: 'unitMoney', title: '金额', width: '100' },
                { prop: 'unitQty', title: '数量', width: '100' },
                { prop: 'fetchTypeName', title: '取货方式', width: '120' },
                { prop: 'shopName', title: '取货门店', width: '160' },
                { prop: 'deliveryDate', title: '取货时间', width: '160' },
                { prop: 'receiverName', title: '姓名', width: '120' },
                { prop: 'receiverPhone', title: '电话', width: '120' },
                { prop: 'receiverAddress', title: '地址', width: '160' },
                { prop: 'remark', title: '备注', width: '160' }
            ],
            total: 0, // 总条数
            // 表格遮罩
            loadingTable: false,
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 20
            },
            params: {
                billId: undefined,
                state: undefined
            },
            shopOrderTip: {
                shop_ORDER_CONFIG_TIP_71: false,
                shop_ORDER_CONFIG_TIP_72: false,
                shop_ORDER_CONFIG_TIP_73: false,
            }
        }
    },
    async created() {
        await this.getList();
        this.getShopOrderTipConfig()
    },
    methods: {
        getShopOrderTipConfig() {
            shopOrderTipConfig().then(res => {
                this.shopOrderTip = res.data
            })
        },
        async setSaveShopOrderTipConfig() {
            saveShopOrderTipConfig(this.shopOrderTip).then(res => {

            })
        },
        // 状态修改监听
        orderState() {
            //  this.setSaveShopOrderTipConfig()
        },
        // 查询表格列表
        async getList(reset = false) {
            if(reset){
                this.queryParams.pageNum = 1
            }
            await this.$nextTick()
            this.loadingTable = true
            const res = await getRoomOrder({ orderInnerStatus: 0, ...this.queryParams })
            this.tableData = res.rows
            this.total = res.total
            this.loadingTable = false
        },
        async getOrderDetails(row) {
            this.orderListDetail = row
            this.params.billId = row.billId
            const res = await getOrderMiddleFroundOneInfo({ billId: row.billId, goodsId: row.goodsId })
            this.orderDetails = res.data
            this.orderGoodsDetails = res.data.middleGroundBillOrderGoodsInfoResps.map(
                (item) => {
                    return item
                }
            )
        },
        // 顶部查询搜索按钮
        searchBtn() {
            this.getList(true)
        },
        async handleButtonClick(state) {
            const { decorationRoomId, goodsId, billId } = this.orderListDetail
            const data = { billId, decorationRoomId, goodsId }
            try {
                if (state === 1) {
                    await printOrder({ billId })// 打印
                } else if (state === 2) {
                    await orderDoing(data)
                } else if (state === 3) {
                    await orderDone(data)
                }
                this.getList();
                this.$message.success('操作成功')
            } catch (error) {

            }
        }
    },
    watch: {
        datetime(newVal) {
            if (newVal) {
                if (this.queryParams.filterTime) {
                    this.queryParams.filterTime = undefined
                }
                this.queryParams.beginBillDate = newVal[0]
                this.queryParams.endBillDate = newVal[1]
            }
            if (!newVal) {
                this.queryParams.beginBillDate = undefined
                this.queryParams.endBillDate = undefined
            }
        },
        //侦听时间
        'queryParams.filterTime': {
            handler(newVal) {
                if (newVal) {
                    if (this.datetime) {
                        this.datetime = undefined
                        this.queryParams.beginBillDate = undefined
                        this.queryParams.endBillDate = undefined
                    }
                    //日期快捷触发
                    this.getList(true)
                }
            },
            immediate: true
        }
    }
}
</script>

<style lang="scss" scoped>
.onlineOrder {
    height: 100%;
    text-align: left;

    .search {
        height: 13.7%;
        margin-bottom: 0.3%;
        background-color: #fff;
        display: flex;
        align-items: center;
        border-radius: 4px;

        //搜索区域
        .left-search {
            width: 82%;

            .row {
                display: flex;
                align-items: center;
                flex-wrap: nowrap;
                height: 38px;
                margin: 1% 2%;

                // 任意时间
                .anyTime {
                    width: 40%;

                    .label {
                        margin-right: 5px;
                        width: 40px;
                    }

                    ::v-deep .el-date-editor--datetimerange.el-input,
                    .el-date-editor--datetimerange.el-input__inner {
                        width: calc(100% - 45px) !important;
                        margin: 0 5px !important;
                    }
                }

                // 快捷时间
                .quickTime {
                    width: 25%;
                    margin: 0 5px;

                    ::v-deep .el-radio-button {
                        width: 33.33% !important;
                    }

                    ::v-deep .el-radio-button__inner {
                        width: 100% !important;
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        text-align: center;
                    }
                }

                .el-input {
                    width: 30%;
                    margin: 0 1% 0 4%;
                }

                //状态
                .state {
                    width: 50%;
                    margin-right: 2%;

                    .label {
                        margin-right: 5px;
                        width: 40px;
                    }

                    .el-radio-group {
                        width: calc(100% - 45px);

                        ::v-deep .el-radio-button {
                            width: 14.28% !important;
                        }

                        ::v-deep .el-radio-button__inner {
                            width: 100% !important;
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            text-align: center;
                        }
                    }
                }
            }
        }

        .right-control {
            width: 18%;
            text-align: right;

            div {
                margin: 1.5%;
            }
        }
    }

    .content {
        height: 86%;
        background-color: #fff;
        border-radius: 4px;
        display: flex;

        //订单详情
        .orderDetail {
            width: 25%;
            height: 100%;
            background-color: #f8f8f9;
            padding: 5px 10px;

            //订单小票
            .orderReceipt {
                width: 100%;
                height: 100%;
                padding: 0 3px;
                background: url(~@/assets/fixedImages/receipt-bg.png);
                background-size: 100% 100%;

                //订单头部标题
                .title {
                    height: 6%;
                    border-bottom: 1px dashed #e5e5e5;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    font-weight: bold;
                }

                //订单内容
                .orderContent {
                    height: 83%;

                    //滚动条
                    .el-scrollbar {
                        height: 100%;
                        overflow: hidden;

                        ::v-deep .el-scrollbar__wrap {
                            overflow-x: hidden;
                        }

                        //订单信息/付款信息/客户信息
                        .orderInfo,
                        .payInfo,
                        .clientInfo {
                            padding: 5px 10px;

                            &.borderB {
                                border-bottom: 1px solid #c1c1c1;
                            }

                            .row {
                                line-height: 25px;
                                display: flex;

                                .label {
                                    text-align: justify;
                                    text-align-last: justify;

                                    &.width77 {
                                        width: 77px;
                                    }

                                    &.width105 {
                                        width: 105px;
                                    }

                                    &.width40 {
                                        width: 40px;
                                    }
                                }

                                .val {
                                    margin-left: 5px;
                                }
                            }
                        }

                        //商品信息
                        .goodsInfo {

                            // 商品标题
                            .goodsTitle-list {
                                display: flex;
                                text-align: center;

                                padding-bottom: 5px;

                                .goodsTitle {
                                    width: 25%;
                                    font-weight: bold;
                                    line-height: 35px;
                                }
                            }

                            //商品详情列表
                            .goodsList {
                                border-bottom: 1px solid #c1c1c1;
                                text-align: center;

                                .goodsItem {
                                    display: flex;

                                    div {
                                        width: 25%;
                                        text-align: center;
                                        flex-wrap: nowrap;
                                    }

                                    goodsItemName {
                                        width: 100%;
                                    }
                                }
                            }

                            .goodsTotal {
                                text-align: right;
                                line-height: 35px;
                                padding-right: 10px;
                            }
                        }
                    }
                }

                //底部按钮
                .footer-btn {
                    height: 9%;
                    padding: 0 10px;
                    border-top: 1px dashed #e5e5e5;
                    display: flex;
                    justify-content: space-around;
                    align-items: center;
                }
            }
        }

        //订单列表
        .orderList {
            width: 75%;
            height: 100%;
            padding: 10px;
        }
    }
}
</style>
